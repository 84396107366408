/*****************
    Variables
******************/
/* Colours */
@color-background: #21467a;
@color-background-alternate: #e8ecf1;
@color-background-dialog: #e8ecf1;
@color-background-event-date: #21467a;
@color-background-footer: #21467a;
@color-background-nav: #21467a;
@color-background-nav-level2: rgba(232, 236, 241, 0.95);
@color-background-nav-link-active: #ba002c;
@color-background-scrollToTop: rgba(60, 60, 60, 0.5);
@color-button-active: #ba002c;
@color-button-inactive: #21467a;
@color-event-date: #fff;
@color-heading2: #21467a;
@color-links: #21467a;
@color-links-active: #ba002c;
@color-links-mainnav-level2: #3c3c3c;
@color-navtab-hover: #ba002c;
@color-text: #3c3c3c;
/* Dimensions */
@dim-header-mobile-metamenu-margin-top: 0.8rem;
@dim-header-mobile-metamenu-line-height: 1rem;
@dim-header-mobile-metamenu-margin-bottom: 0.8rem;
@dim-header-mobile-navbar-height: 35px;
@dim-header-mobile-navbar-margin-bottom: 15px;
@dim-header-mobile-namebar-line-height: 3.5rem;
@dim-header-desktop-metamenu-margin-top: @dim-header-mobile-metamenu-margin-top;
@dim-header-desktop-metamenu-line-height: @dim-header-mobile-metamenu-line-height;
@dim-header-desktop-metamenu-margin-bottom: @dim-header-mobile-metamenu-margin-bottom;
@dim-header-desktop-logos-margin-top: 0;
@dim-header-desktop-logos-img-height: 50px;
@dim-header-desktop-logos-margin-bottom: 20px;
@dim-header-desktop-namebar-line-height: @dim-header-mobile-namebar-line-height;
@dim-header-desktop-navbar-line-height: 3.5rem;

@dim-header-mobile-height: calc(
  @dim-header-mobile-metamenu-margin-top +
    @dim-header-mobile-metamenu-line-height +
    @dim-header-mobile-metamenu-margin-bottom + @dim-header-mobile-navbar-height +
    @dim-header-mobile-navbar-margin-bottom +
    @dim-header-mobile-namebar-line-height
);

/****************************
    Used for all elements
*****************************/
* {
  box-sizing: border-box;

  font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode",
    "Tahoma sans-serif", sans-serif;

  hyphens: none;
}
/******************************
    General classes/mixins
*******************************/
.hidden {
  display: none;
}

.max-width {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.screenreader {
  position: absolute;
  top: -99em;
  left: -99em;
  width: 1px;
  height: 1px;
}

/************************************************
    Containers (same order as in HTML source)
*************************************************/
body {
  background-color: #fff;

  margin: @dim-header-mobile-height 0 0;
}

.target-anchor:before {
  content: " ";

  display: block;

  height: calc(
    @dim-header-mobile-metamenu-margin-top +
      @dim-header-mobile-metamenu-line-height +
      @dim-header-mobile-metamenu-margin-bottom +
      @dim-header-mobile-navbar-height + @dim-header-mobile-navbar-margin-bottom +
      @dim-header-mobile-namebar-line-height + 1em
  );

  margin: calc(
      -1 * (@dim-header-mobile-metamenu-margin-top +
            @dim-header-mobile-metamenu-line-height +
            @dim-header-mobile-metamenu-margin-bottom +
            @dim-header-mobile-navbar-height +
            @dim-header-mobile-navbar-margin-bottom +
            @dim-header-mobile-namebar-line-height + 1em)
    )
    0 0;
}

header {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  .meta-menu {
    .max-width();
    line-height: @dim-header-mobile-metamenu-line-height;
    margin: @dim-header-mobile-metamenu-margin-top auto
      @dim-header-mobile-metamenu-margin-bottom;
    padding: 0 10px 0 0;
    text-align: right;

    ul {
      display: inline-block;
      font-size: 0.8125rem;
      list-style-type: none;
      margin: 0;
      padding: 0;

      &.language-selector {
        li {
          text-transform: uppercase;
        }
      }

      &.menuitems {
        li:last-child {
          padding-right: 0;
        }
      }

      li {
        color: #555;
        display: inline;
        padding-left: 0.5em;
        padding-right: 0.5em;
        font-family: "Arial Regular", sans-serif;

        a {
          color: @color-links;
          font-family: "Arial Regular", sans-serif;

          &:link {
            text-decoration: none;
          }

          &:active,
          &:focus,
          &:hover {
            text-decoration: underline;
          }
        }

        span.loggedin {
          color: @color-links;

          span.username {
            color: @color-links-active;
          }
        }
      }
    }
  }

  div.navbar-mobile {
    display: flex;
    height: @dim-header-mobile-navbar-height;
    margin-bottom: @dim-header-mobile-navbar-margin-bottom;
    vertical-align: middle;

    a.logo-institut,
    a.logo-uni {
      img {
        max-height: 35px;
      }
    }

    a.logo-uni {
      flex: 1;
      padding-left: 10px;
    }

    a.logo-institut {
      flex: 1;
      text-align: center;
    }

    div {
      flex: 1;
      padding-right: 10px;
      text-align: right;

      button.search-button {
        background-color: #fff;
        border: none;
        color: @color-button-inactive;
        cursor: pointer;
        /*flex: 1;*/
        font-size: 30px;
        margin-right: 0.25em;
        padding: 0;
        width: 30px;
        height: 35px;
        vertical-align: top;

        &.open {
          color: @color-navtab-hover;
        }
      }

      button.nav-mobile-toggle-button {
        background-color: #fff;
        border: none;
        cursor: pointer;
        /*flex: 1;*/
        margin-top: 2px;
        margin-left: 0.25em;
        padding: 0;
        position: relative;
        width: 30px;
        height: 30px;

        span.hamburger-bar {
          background-color: @color-background;
          border-radius: 1.5px;
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transition: all 0.3s ease-in-out;
          width: 30px;
          height: 3px;
        }

        span.hamburger-bar.top {
          margin-top: -10px;
          transform: rotate(0deg);
          transform-origin: 0 0;
        }

        span.hamburger-bar.middle {
          opacity: 1;
        }

        span.hamburger-bar.bottom {
          margin-top: 10px;
          transform: rotate(0deg);
          transform-origin: left center;
        }

        &.open {
          span.hamburger-bar {
            background-color: #ba002c;
            transition: all 0.3s ease-in-out;
          }

          span.hamburger-bar.top {
            /*transform: rotate(45deg) translate(-50%,-50%);*/
            transform: rotate(45deg);
          }

          span.hamburger-bar.middle {
            opacity: 0;
          }

          span.hamburger-bar.bottom {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  #search-mobile {
    background-color: @color-background-dialog;

    margin-right: -100vw;

    overflow: hidden;

    position: fixed;
    top: 6em;
    left: 100vw;
    height: calc(100vh - 6em);

    transition: left 0.5s ease-in;
    width: 100vw;

    z-index: 1000;

    &.open {
      left: 0;
    }

    h2 {
      color: @color-button-inactive;
      font-weight: normal;
      text-align: center;
    }

    input[type="text"] {
      border: none;
      display: block;
      font-size: 1rem;
      line-height: 3rem;
      margin: 2rem auto 1.5rem;
      padding: 0 1rem;
      width: 80vw;
    }

    button[type="submit"] {
      background-color: @color-button-inactive;
      border: none;
      color: #fff;
      display: block;
      font-size: 1rem;
      font-weight: bold;
      line-height: 3rem;
      margin: 1.5rem auto;
      width: 80vw;

      &:focus,
      &:hover {
        background-color: @color-button-active;
      }
    }
  }

  nav#nav-mobile {
    background-color: transparent;
    display: block;
    overflow: scroll;
    padding-top: 1px;
    position: fixed;
    top: 6em;
    transition: height 0.5s ease-in;
    width: 100vw;
    height: 1px;

    &.open {
      height: 100vh;
    }

    #nav-mobile-tablist {
      background-color: @color-background;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        &#nav-mobile-sliding-toggler,
        &#nav-mobile-tree-toggler {
          flex: 1;
        }

        &#nav-mobile-infos-toggler,
        &#nav-mobile-quicklinks-toggler {
          flex: 2;
        }
        font-size: 1.25rem;
        font-weight: 700;
        padding: 0.6em 0.1em;
        text-align: center;
        text-transform: uppercase;
      }

      li[aria-selected="false"] {
        color: #3c3c3c;

        &:focus,
        &:hover {
          background-color: @color-navtab-hover;
          color: #fff;
        }
        cursor: pointer;
      }

      li[aria-selected="true"] {
        color: #fff;
        display: inline-block;
      }
    }

    .nav-mobile-tab[aria-hidden="true"] {
      display: none;
    }

    li[aria-selected="false"] {
      background-color: #e8ecf1;
    }

    li[aria-selected="true"] {
      background-color: @color-background;
    }

    #nav-mobile-infos,
    #nav-mobile-quicklinks,
    #nav-mobile-sliding,
    #nav-mobile-tree {
      background-color: @color-background;
      height: 100%;

      ul {
        list-style: none;
        margin-top: 0;
        padding-left: 0;
      }

      ul > li {
        border-bottom: 1px solid #fff;
        font-size: 1.175rem;
        position: relative;

        &:focus,
        &:hover {
          background: @color-navtab-hover;
        }
      }

      ul > li > a {
        display: block;
        padding-left: 0.5em;
        text-decoration: none;
        width: 100%;
        height: 100%;

        &:link,
        &:visited {
          color: #fff;
        }

        &:focus,
        &:hover {
          background: @color-navtab-hover;
        }
      }

      & > ul > li > a {
        line-height: 2.5em;
      }

      ul > li > ul > li > a {
        padding-left: 2em;
      }
    }

    #nav-mobile-tree {
      ul > li {
        position: relative;
      }

      ul > li > button {
        font-size: 1.175rem;
      }

      ul > li button {
        background-color: transparent;
        border: none;
        color: #fff;
        cursor: pointer;
        line-height: 2.5em;
        padding: 0;
        position: absolute;
        left: 0.5em;
      }

      ul > li.collapsed > ul {
        display: none;
      }

      ul > li.expanded ul li,
      ul > li.expanded ul li ul li {
        background-color: @color-background;
      }

      ul > li > ul {
        padding-left: 1em;

        li:last-child {
          border-bottom: none;
        }
      }
    }

    #nav-mobile-sliding {
      button {
        background-color: transparent;
        border: none;
        color: #fff;
        cursor: pointer;
        font-size: 1.175rem;
        line-height: 2.75rem;
        padding-left: 0.5em;
        text-align: left;
        width: 100%;
        /*height: 100%;*/
        &.expanded {
          background-color: @color-button-active;
        }
      }

      ul > li > div {
        background-color: @color-background-dialog;
        padding-left: 1.5em;

        &.collapsed {
          display: none;
        }

        &.expanded {
          display: block;
        }

        & > a > i {
          margin-left: 0.4em;
          margin-right: 0.4em;
        }

        & > a:link {
          color: @color-links;
          text-decoration: none;
        }

        & > a:focus,
        & > a:hover {
          text-decoration: underline;
        }

        & > ul > li {
          border-bottom-color: @color-links-mainnav-level2;
          font-size: 0.9rem;
          line-height: 2.25rem;
          position: static;
        }

        & > ul > li:last-child {
          border-bottom: none;
        }

        & > ul > li > a {
          display: inline-block;
          margin-left: 0.4em;
          padding-left: 0;
          /*width: calc(100% - 3.5em);*/
          &:link,
          &:visited {
            color: @color-links-mainnav-level2;
          }

          &:focus,
          &:hover {
            color: #fff;
          }
        }

        & button.back-button {
          color: @color-links-mainnav-level2;
          display: inline-block;
          width: auto;

          &:focus,
          &:hover {
            background-color: @color-button-active;
            color: #fff;
          }
        }

        & ul > li > button.slide-button {
          color: @color-links-mainnav-level2;
          display: inline-block;
          font-size: 0.9rem;
          line-height: 2.25rem;
          position: relative;

          &:focus,
          &:hover {
            background-color: @color-button-active;
            color: #fff;
          }
          width: 100%;

          i {
            font-size: 1.375rem;
            line-height: 2.25rem;
            position: absolute;
            top: 0;
            right: 1em;
          }
        }

        & > ul > li:focus > a,
        & > ul > li:focus > button.nav-mobile-sliding-expand,
        & > ul > li:hover > a,
        & > ul > li:hover > button.nav-mobile-sliding-expand {
          color: #fff;
        }

        & > ul > li > div {
          position: absolute;
          top: 6em;
          left: 100vw;
          width: 100vw;
          height: 100%;
        }

        & > ul > li > div.expanded {
          background-color: @color-background-dialog;
          display: block;
          position: absolute;
          top: 6em;
          left: 0;
          z-index: 99;
          width: 100vw;
          height: 100%;
        }
      }
    }
  }

  .logos {
    .max-width();
    display: flex;

    a {
      flex: 1;

      &.logo-uni {
        text-align: left;
      }

      &.logo-institut {
        text-align: right;
      }
    }
  }

  nav {
    background-color: @color-background-nav;

    & > ul {
      .max-width();
      list-style-type: none;
      padding: 0;
    }

    & > ul > li {
      display: inline-block;
      font-size: 1.375rem;
      font-weight: 400;
    }

    & > ul > li:not(:first-child) a {
      padding-left: 0.5em;
    }

    & > ul > li:not(:last-child) a {
      padding-right: 0.5em;
    }

    & > ul > li > a {
      display: inline-block;
      padding-top: 0.5em;
      padding-bottom: 0.5em;

      &:link,
      &:visited {
        color: #fff;
        text-decoration: none;
      }

      &:focus,
      &:hover {
        background-color: @color-background-nav-link-active;
      }
    }

    & > ul > li > ul {
      display: none;
    }
  }

  div#logo-bar {
    display: none;
  }

  div#institute-name-bar {
    background-color: @color-background-dialog;
    color: @color-links;
    /*height: @dim-header-mobile-namebar-lineheight;*/
    font-size: 1.375rem;
    line-height: @dim-header-mobile-namebar-line-height;
    margin: 0;
    overflow: hidden;
    padding: 0 1rem;

    a {
      &:link,
      &:visited {
        color: @color-links;

        text-decoration: none;
      }

      &:active,
      &:focus,
      &:hover {
        color: @color-links-active;
      }
    }

    div {
      .max-width();
    }

    div.institute-name {
      display: none;
    }
  }

  nav#nav-desktop {
    display: none;
  }
}

main {
  font-family: "Arial Regular", sans-serif;
  overflow: auto;
  padding: 1rem;

  &.navigation.welcomePageIfS {
    padding: 1rem 0;
  }

  nav.breadcrumbs {
    margin: 0 0 1em;

    ul {
      list-style: none;
      padding-left: 0;

      li {
        display: inline-block;
        font-size: 0.8em;

        &:not(:last-child)::after {
          color: @color-links;
          content: " \f054";
          display: inline-block;
          font-family: "FontAwesome";
          margin-left: 0.5em;
        }

        a {
          &:link,
          &:visited {
            color: @color-links;
            text-decoration: none;
          }

          &:active,
          &:focus,
          &:hover {
            color: @color-links-active;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .main.publicpersonalprofile h1 {
    line-height: normal;
  }

  nav.subnav {
    display: none;
  }

  a {
    &:link,
    &:visited {
      color: @color-links;
    }

    &:focus,
    &:hover {
      color: @color-links-active;
    }

    .fa {
      margin-right: 0.33em;
    }
  }

  a.more-link {
    display: block;
    margin-top: 0.2em;
    text-decoration: underline;

    &:after {
      content: " \f105";
      font-family: "FontAwesome";
    }

    &:link,
    &:visited {
      color: @color-links;
    }

    &:focus,
    &:hover {
      color: @color-links-active;
    }
  }

  a#scrollToTop {
    background-color: @color-background-scrollToTop;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 2.2rem;
    line-height: 3rem;
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    width: 3rem;
    height: 3rem;
    text-align: center;

    i {
      margin-right: 0;
    }

    &.active {
      display: block;
    }

    &.inactive {
      display: none;
    }

    &:focus,
    &:hover {
      background-color: @color-button-active;
    }
  }

  div.contentitem-detail.scipublication {
    div.data dl div.articles {
      dt {
        margin-bottom: 0.66em;
      }

      dd ul.articles {
        list-style: none;

        padding-left: 0;

        li {
          margin-bottom: 0.66em;
        }
      }
    }
  }

  div.ifs-welcome {
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      "topics1 topics1 topics2 topics2 topics3 topics3"
      "news    news    news    misc2   misc2   misc2"
      "info1   info1   info2   info2   info3   info3";

    position: relative;

    #more-info-buttons-mobile {
      padding-top: 0.75em;
      padding-right: 0.5em;

      position: fixed;
      top: calc(
        @dim-header-mobile-metamenu-margin-top +
          @dim-header-mobile-metamenu-line-height +
          @dim-header-mobile-metamenu-margin-bottom +
          @dim-header-mobile-navbar-height +
          @dim-header-mobile-navbar-margin-bottom
      );
      right: 0;

      z-index: 999;

      &.nav-open {
        z-index: 1;
      }

      button {
        background-color: transparent;

        border: 2px solid @color-links;
        // border-top: 1px solid rgba(66,66,66, 0.5);
        // border-right: none;
        // border-bottom: 1px solid rgba(66,66,66, 0.5);
        // border-left: 1px solid rgba(66,66,66, 0.5);

        border-radius: 0.33em;
        // border-top-left-radius: 0.25em;
        // border-bottom-left-radius: 0.25em;

        color: @color-links;

        font-size: 1.375rem;

        &#show-events-box {
          margin-left: 0.5em;
          margin-right: 0.5em;
        }
      }
    }

    #topicBox1 {
      grid-area: topics1;
    }

    #topicBox2 {
      grid-area: topics2;
    }

    #topicBox3 {
      grid-area: topics3;
    }

    #miscBox1 {
      position: fixed;
      top: @dim-header-mobile-height;
      right: 0;

      transform: translateX(100%);

      transition: transform 0.5s ease-in;

      z-index: 999;

      &.visible {
        transform: translateX(0%);
      }
    }

    #miscBox2 {
      grid-area: misc2;
    }

    #miscBox3 {
      position: fixed;
      top: @dim-header-mobile-height;
      right: 0;

      transform: translateX(100%);

      transition: transform 0.5s ease-in;

      z-index: 999;

      &.visible {
        transform: translateX(0%);
      }
    }

    #events-and-news {
      grid-area: news;

      #events {
        background-color: #fff;

        // position: fixed;
        // top: @dim-header-mobile-height;
        position: absolute;
        top: 0;
        right: 0;

        transform: translateX(100%);

        transition: transform 0.5s ease-in;

        &.visible {
          transform: translateX(0%);
        }
      }
    }

    #infoBox1 {
      grid-area: info1;
    }

    #infoBox2 {
      grid-area: info2;
    }

    #infoBox3 {
      grid-area: info3;
    }

    .miscBox {
      // div, h3, h4, h5, h6, p {
      //
      //     margin-top: inherit;
      //     margin-right: 1em;
      //     margin-bottom: inherit;
      //     margin-left: 1em;
      // }

      div {
        margin-top: inherit;
        margin-right: 1em;
        margin-bottom: inherit;
        margin-left: 1em;

        &.partner-logos {
          div.partner-logo {
            a {
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    // .miscBox:nth-child(odd) {
    #miscBox1,
    #miscBox3,
    #miscBox5,
    #miscBox7,
    #miscBox9 {
      background-color: @color-background-alternate;
    }

    // .miscBox:nth-child(even) {
    #miscBox2,
    #miscBox4,
    #miscBox6,
    #miscBox8,
    #miscBox10 {
      background-color: @color-background-nav;
      color: #fff;

      h2 {
        color: #fff;
      }

      div {
        a {
          &:active,
          &:focus,
          &:hover {
            color: #fff;
            // color: @color-links-active !important;
          }

          &:link,
          &:visited {
            color: #fff;
          }
        }
      }
    }

    .topicBox,
    .infoBox {
      font-size: 0.8em;

      h2 a:after {
        content: " ";

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      p {
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
    }

    .miscBox {
      h2 {
        font-size: 1.2em;
      }
    }

    .topicBox,
    .infoBox,
    .miscBox {
      position: relative;

      padding: 0 0 0.5em 0;

      h2 {
        a {
          text-decoration: none;

          &:link,
          &:visited {
            // color: #fff;
            color: @color-links;
          }

          &:active,
          &focus,
          &:hover {
            color: @color-links-active;
          }
        }
      }

      // h2 a:after {
      //
      //     content: " ";
      //
      //     position: absolute;
      //     top: 0;
      //     right: 0;
      //     bottom: 0;
      //     left: 0;
      // }

      h2.not-illustrated-header {
        margin: 0.5em 0.66em;
      }

      h2.illustrated-header {
        font-size: 0.9em;

        position: relative;

        img {
          width: 100%;
        }

        & > a,
        & > span {
          // background-color: rgba(255,255,255,0.5);

          //display: flex;

          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          & > a,
          & > span {
            background-color: rgba(255, 255, 255, 0.6h);

            min-height: 3.25em;

            padding: 0.33em 0.2em;

            position: absolute;
            bottom: 0;
            left: 0;

            text-align: center;

            width: 100%;
          }

          & > span {
            color: @color-links;
          }
        }
      }
    }

    #events {
      ul {
        list-style: none;
      }

      .event-date {
        line-height: 2.5em;

        vertical-align: middle;

        .event-date-day {
          border-radius: 50%;

          background-color: @color-background-nav;

          color: #fff;

          display: inline-block;

          font-weight: bold;

          line-height: 2.5em;

          margin-right: 0.5em;

          text-align: center;
          vertical-align: middle;

          width: 2.5em;
          height: 2.5em;
        }

        .event-date-month {
          color: @color-links;

          font-weight: bold;

          vertical-align: middle;
        }
      }
    }

    #news {
      padding-left: 0.5em;

      h3 {
        margin-top: 0.25em;
      }

      img {
        float: left;

        max-width: 33%;

        margin-right: 0.5em;
        margin-bottom: 0.5em;
      }

      ul {
        list-style: none;

        padding-left: 0;
      }
    }

    dialog.topicBoxDialog {
      background-color: rgba(255, 255, 255, 1);

      border: 2px solid @color-background-nav;

      display: block;

      // overflow-y: scroll;

      position: fixed;
      top: calc(
        @dim-header-mobile-metamenu-margin-top +
          @dim-header-mobile-metamenu-line-height +
          @dim-header-mobile-metamenu-margin-bottom +
          @dim-header-mobile-navbar-height +
          @dim-header-mobile-navbar-margin-bottom +
          @dim-header-mobile-namebar-line-height
      );
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 999;

      width: calc(100vw);
      height: calc(
        100vh -
          (
            @dim-header-mobile-metamenu-margin-top +
              @dim-header-mobile-metamenu-line-height +
              @dim-header-mobile-metamenu-margin-bottom +
              @dim-header-mobile-navbar-height +
              @dim-header-mobile-navbar-margin-bottom +
              @dim-header-mobile-namebar-line-height
          )
      );

      transition: all ease 2s;

      div.dialog-header {
        background-color: @color-background-nav;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 3rem;

        button.closeButton {
          background-color: transparent;

          border: none;

          color: #fff;

          cursor: pointer;

          font-size: 2rem;

          position: absolute;
          top: 0;
          right: 0;
        }
      }

      div.dialog-body {
        overflow: auto;

        padding: 0.5em 1em;

        position: absolute;
        top: 3rem;
        left: 0;

        width: 100%;
        height: calc(100% - 3rem);

        .spinner {
          animation: spinner 2.5s infinite ease-in-out;

          // border: 0.75em solid #ddd;
          // border-top-color: #999;
          // border-bottom-color: #999;
          border: 1em solid @color-background-alternate;
          border-top-color: @color-background-nav;
          border-bottom-color: @color-background-nav;
          border-radius: 50%;

          margin-top: -4em;
          margin-left: -4em;

          position: absolute;
          top: 50%;
          left: 50%;

          width: 8em;
          height: 8em;
        }

        @keyframes spinner {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(540deg);
          }
        }

        .profiles {
          .profile {
            h3 {
              margin-top: 2em;
            }

            .profile-body {
              // display: flex;
              // justify-content: space-between;

              .image {
                float: none;

                margin-top: 0;

                // max-width: 33.3%;

                img {
                  display: block;

                  width: 100%;
                }
              }

              .profile-text {
                h4:first-child {
                  margin-top: 0;
                }
              }
            }

            // .image {
            //
            //     margin-top: 0;
            //
            //     max-width: 33.3%;
            //
            //     img {
            //
            //         display: block;
            //
            //         width: 100%;
            //     }
            // }
          }
        }
      }
    }
  }

  div.lead {
    // font-weight: bold;

    line-height: 1.5em;

    margin-bottom: 1em;
  }

  div#login {
    h1,
    h2 {
      color: @color-heading2;
    }

    h2.form-header {
      color: @color-text;
    }

    input[type="submit"] {
      background-color: @color-background;

      border: none;

      color: #fff;

      font-size: 1em;
      font-weight: bold;

      margin-top: 1em;

      width: 7em;
      height: 3em;

      text-align: center;

      &#save {
        margin-right: 1.5em;
      }
    }
  }

  div.image {
    margin: 1em;

    img {
      max-width: 100%;
    }
  }

  h1 {
    hyphens: auto;
  }

  h2 {
    color: @color-heading2;
    margin-top: 0;
  }

  h2.section-heading {
    // background-color: @color-background-alternate;
    // display: table;font-weight: 300;
    // font-weight: 300;
    // margin-left: -100%;
    // padding: 0.4em 1.25em 0.4em 1.25em;
    span {
      background-color: @color-background-alternate;
      font-weight: 300;
      padding: 0.4em 1.25em 0.4em 0.75em;
    }
  }

  .welcome-page-teaser {
    background-color: @color-background-alternate;
    margin-bottom: 1em;
    padding: 1em;

    h1 {
      margin-top: 0;
    }

    .text {
      ol,ul {
        list-style-position: inside;
        padding-inline-start: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  div.fsi-links {
    a {
      margin-right: 1em;
    }
  }

  div.infoboxes {
    div.infobox {
      &:nth-child(2n) {
        background-color: @color-background-alternate;
      }
    }
  }

  div.infoboxes-slider {
    height: 16rem;
    position: relative;
    overflow: hidden;

    button.next-item,
    button.previous-item {
      background-color: rgba(60, 60, 60, 0.5);
      border: none;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      display: none;
      font-size: 3rem;
      margin-top: -2rem;
      position: absolute;
      top: 50%;
      z-index: 99;
      width: 4rem;
      height: 4rem;

      &:focus,
      &:hover {
        background-color: @color-button-active;
        color: #fff;
      }
    }

    button.previous-item {
      left: 1rem;
    }

    button.next-item {
      right: 1rem;
    }

    div.infobox {
      /*transition: ease-in-out 400ms;*/
    }

    div.infobox {
      position: absolute;
      width: 100%;
      top: 0;

      &.current {
        left: 0;
      }

      &.prev {
        left: -100%;
      }

      &.next {
        left: 100%;
      }

      &.current.prev {
        transition: all 0.5s;
        transform: translate3d(100%, 0, 0);
      }

      &.current.next {
        transition: all 0.5s;
        transform: translate3d(-100%, 0, 0);
      }
    }
    // div.infobox.current {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    // }
    //
    // div.infobox.current.next {
    //     transition: all 0.5s;
    //     transform: translate3d(-100%, 0, 0);
    // }
    //
    // div.infobox.next {
    //     position: absolute;
    //     top: 0;
    //     left: 100%;
    // }
    &:focus,
    &:hover {
      button.next-item,
      button.previous-item {
        display: block;
      }
    }
  }

  .edit-link {
    display: none;
  }

  div.scipublications.export-links {
    ul {
      list-style: none;
      padding-left: 0;

      li {
        display: inline-block;
        margin-right: 1em;
      }
    }
  }

  div#search-results {
    ul {
      list-style: none;

      padding-left: 0;

      li {
        margin-bottom: 1em;
      }
    }
  }

  aside {
    margin: 1em 0;

    div.file-attachments,
    div.related-links,
    div.side-note {
      // background-color: @color-background-nav;
      // color: #fff;
      margin: 1em 0;
      padding: 1em;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        &:link,
        &:visited {
          text-decoration: none;
        }

        &:active,
        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

      h2 {
        color: #fff;
      }

      h3 {
        margin-top: 0;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 1em;

          strong {
            font-size: 1.2em;
            font-style: italic;
            font-weight: bold;
          }
        }
      }
    }

    div.file-attachments,
    div.related-links {
      background-color: @color-background-nav;
      color: #fff;

      a {
        &:link,
        &:visited {
          color: #fff;
        }
      }
    }

    div.side-note {
      background-color: @color-background-alternate;

      a {
        &:link,
        &:visited {
          color: @color-links;
        }
      }

      h2 {
        color: @color-text;
      }
    }
  }

  div.related-links {
      word-wrap: break-word;
  }

  div.infoboxes,
  div.infoboxes-slider {
    div.infobox {
      line-height: 1.5em;

      padding: 0.75rem;

      h2 {
        font-size: 1.1rem;
      }
    }
  }

  div.image-attachments {
    float: left;
    margin: 0 2em 1em 0;
    /*position: relative;*/
    width: 254px;
    max-width: 100%;

    figure {
      margin: 0;
    }

    div {
      margin: 0 0 10px;
      padding: 0;
      position: relative;

      img {
        max-width: 100%;
        vertical-align: bottom;
      }
    }
    /*&.single {
            cursor: pointer;
        }

        &.single div::after {

            background-color: rgba(100, 100, 100, 0.5);

            color: #fff;

            content: "\f002";

            font-family: 'FontAwesome';
            font-size: 12px;

            padding: 5px;

            position: absolute;
            bottom: 0;
            left: 0;
        }*/
  }

  div.item-list {
    div.pager {
      span.prev {
        margin-right: 0.5em;
      }

      span.next {
        margin-left: 0.5em;
      }
    }

    form.filter-controls {
      background-color: @color-background-alternate;
      margin-top: 1em;
      margin-bottom: 1em;
      padding: 0.5em;

      fieldset {
        border-color: @color-text;
        border-width: 1px;

        input,
        select {
          margin-right: 2em;
        }

        button,
        input[type="submit"] {
          margin-left: 0.5em;
        }

        &.sortingOptions {
          margin-top: 1em;
        }
      }
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        border-bottom: 1px solid #ddd;

        // display: flex;

        margin-bottom: 1em;

        padding-bottom: 1em;

        overflow: auto;

        &.scipublication-list {
        }

        div.scidepartment-list {
          a.scidepartment-title {
            display: inline-block;
            font-size: 1em;
            font-weight: bold;
            margin-bottom: 1em;
          }
        }

        div.sciproject-list {
          a {
            display: block;
            font-weight: bold;
            margin-bottom: 0.3em;
          }

          div.lifespan,
          div.members {
            font-weight: bold;
          }

          div.members {
            margin-bottom: 0.3em;
          }
        }

        figure {
          img {
            max-width: 100%;
          }
        }

        span.scipublication-list {
          span.authors {
          }

          a {
            font-weight: bold;
          }

          span {
            display: inline;
          }
        }

        dl {
          dt {
            font-weight: bold;
          }

          dd {
            margin-left: 0;
            margin-bottom: 0.5em;

            dl {
              dd,
              dt {
                display: inline-block;
              }
            }
          }
        }

        figure {
          // float: left;

          // flex: 1;

          margin: 0 1em 1em 0;

          // max-width: 10em;
          max-width: 100%;

          img {
            // max-width: 10em;
            // max-width: 100%;
          }
        }

        h3 {
          margin-top: 0;
        }
      }
    }
  }

  div.item-list.grid {
    ul {
      display: grid;
      grid-gap: 1em 1.5em;
      grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));

      li {
        display: block;

        padding: 0.5em 1em;

        &:nth-child(odd) {
          background-color: @color-background-alternate;
        }

        div.scidepartment-list {
        }

        figure {
          display: block;

          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  // div.item-list.sci-departments-list {
  //     ul {
  //         display: grid;
  //         grid-gap: 1em 1.5em;
  //         grid-template-columns: 1fr 1fr;
  //
  //         li {
  //
  //             display: block;
  //
  //             padding: 0.5em 1em;
  //
  //             div.scidepartment-list {}
  //
  //             figure {
  //
  //                 display: block;
  //
  //                 max-width: 100%;
  //                 width: 100%;
  //
  //                 img {
  //                     width: 100%;
  //                 }
  //             }
  //         }
  //     }
  // }

  div.item-list.member-list {
    ul.item-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(17.5em, 1fr));
      grid-column-gap: 1em;
      grid-row-gap: 1em;

      li {
        margin-bottom: 0;

        // padding: 1em;
        img {
          max-width: 100%;

          width: auto;
          height: auto;

          clip-path: circle(50% at 50% 50%);
        }
      }

      li:nth-child(odd) {
        // background-color: @color-background-alternate;
      }

      li:nth-child(even) {
        // background-color: @color-background-alternate;
      }
    }
  }

  div.item-list.news-list.welcome-page {
    ul {
      li {
        figure {
          img {
            width: 100%;
          }
        }
      }
    }
  }

  div#contentitem-area {
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-top: 0;
      margin-bottom: 0.75em;
    }

    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-top: 0;
      margin-bottom: 0.6em;
    }

    h3,
    h4,
    h5,
    h6 {
      margin-top: 1.5em;
    }

    p:last-child {
      margin-bottom: 1.5em;
    }

    .text {
        ol, ul {
        list-style-position: inside;
        padding-inline-start: 0;
        }
    }
  }

  div#contentitem-area:hover div.edit-link {
    display: block;

    position: absolute;
    top: 0;
    right: 0;
  }

  div.contentitem-detail div.main div.mpa-toc,
  div.ppp.multipart-article div.mpa-toc {
    background-color: @color-background-alternate;

    margin: 0 2em 2em 0;

    padding: 1.25em;

    width: 100%;

    h3 {
      margin-top: 0;
      margin-bottom: 1em;
    }

    ul {
      margin: 0.33em 0 0;

      // padding-left: 1em;
      padding-left: 0;

      list-style: none;

      li {
        color: @color-links;

        // line-height: 2em;

        &:not(:last-child) {
          margin-bottom: 1em;
        }

        &:hover {
          color: @color-links-active;
        }
      }
    }
  }

  div.ppp.multipart-article div.mpa-toc {
    float: right;
    width: 16em;
    margin-left: 1em;
  }

  div.multipart-article {
    div.main {
      div {
        h2:not(:first-child) {
          margin-top: 1em !important;
        }
      }
    }

    /*ul.mpa- toc {

            columns: 2 ;

            li {
                a {
                    &:link, &:visited {
                        color: @color-links;

                        text-decoration: underline;
                    }

                    &:focus, &:hover {
                        color: @color-button-active;
                    }
                }
            }
        }*/
  }
  div.news-list.welcome-page {
    ul.news-list.welcome-page {
      li.hidden {
        display: none;
      }
    }

    div.news-buttons {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      width: calc(3.4rem * 2 + 1em * 4 + 4rem);
    }

    button.less-news-button,
    button.more-news-button {
      background-color: @color-background-alternate;
      border: none;
      border-radius: 50%;
      color: @color-links;
      cursor: pointer;
      // display: block;
      display: inline-block;
      font-size: 2rem;
      // margin-left: auto;
      // margin-right: auto;
      margin-left: 0.5em;
      margin-right: 0.5em;
      padding: 0;
      width: 3.4rem;
      height: 3.4rem;

      &.hidden {
        display: none;
      }

      &:focus,
      &:hover {
        background-color: @color-button-active;
        color: #fff;
      }
    }

    button.less-news-button {
    }

    button.more-news-button {
    }

    ul.news-list.welcome-page > li > figure {
      vertical-align: middle;
    }
  }

  div.scidepartment-details {
    div.tab,
    div.one-page-mode-tab {
      div.image-attachments {
        float: none;

        width: 100%;
      }

      &.summary {
        dl {
          dt {
            font-weight: bold;
          }

          dd {
            margin-left: 0;
            margin-bottom: 1em;

            padding-left: 0;
          }
        }
      }
    }

    div.tab.members,
    div.one-page-mode-tab.members {
      ul.item-list {
        list-style: none;

        padding-left: 0;

        li {
          border-bottom: 1px solid #ddd;

          // margin-bottom: 1em;

          padding-top: 1em;
          padding-bottom: 1em;

          div.person-list {
            a.person-name {
              display: block;

              font-weight: bold;

              margin-bottom: 0.33em;
            }

            dl {
              div {
                display: inline-block;

                dd,
                dt {
                  display: inline-block;
                }

                dd {
                  margin-left: 0;
                  margin-right: 1em;
                }

                dt {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }

    div.tab.projects,
    div.one-page-mode-tab.projects {
      ul {
        list-style: none;

        padding-left: 0;

        li {
          border-bottom: 1px solid #ddd;

          margin-bottom: 1em;

          padding-bottom: 1em;

          div.sciproject-list {
            a {
              display: block;

              font-weight: bold;

              margin-bottom: 0.5em;
            }

            div.lifespan {
            }

            div.members {
              margin-bottom: 0.5em;
            }
          }
        }
      }
    }
  }

  div.sciproject-details {
    div.project-desc {
      p.shortdesc {
        font-weight: bold;
      }
    }

    div.project-summary {
      div.image-attachments {
        float: none;

        width: 100%;
      }
    }
  }

  div.scipublication {
    dt {
      font-weight: bold;
    }

    dd {
      margin-bottom: 1em;
      margin-left: 0;
    }
  }

  div.welcome-page-header-image {
    width: 100vw;
  }

  form {
    label {
      margin-right: 0.5em;
    }
  }

  ul.nav-tabs {
    // background-color: @color-background-alternate;

    list-style: none;

    margin-top: 0;

    padding-left: 0;

    li {
      background-color: @color-background-alternate;
      margin-top: 0.1em;
      margin-bottom: 0.1em;

      a {
        display: block;

        // line-height: 1.66em;

        padding: 0.7em 1em;

        text-decoration: none;
        &:link,
        &:visited {
          color: @color-text;
        }
        //
        &:focus,
        &:hover {
          background-color: @color-links-active;
          color: #fff;
          text-decoration: underline;
        }
      }
      //
      &.active {
        a {
          background-color: @color-background;
          color: #fff;
        }
      }
    }
  }

  // ul,
  // ol {
  //   list-style-position: outside;
  //   padding-left: 1em;
  // }

  ul.news-list {
    list-style: none;
    padding: 0;

    li {
      figure {
        margin: 0;

        img {
          width: 100%;
        }
      }

      h3 {
        color: @color-heading2;
        font-weight: 300;
        text-decoration: none;

        a {
          &:link,
          &:visited {
            color: @color-heading2;
            text-decoration: none;
          }

          &:focus,
          &:hover {
            /*text-decoration: underline;*/
            border-bottom: 1px solid @color-heading2;
          }
        }
      }

      .lead {
        color: @color-text;
        line-height: 1.5em;
      }
    }
  }

  div.event {
    dl.event-details {
      background-color: @color-background-alternate;

      // display: grid;
      // grid-template-columns: 7em max-content;
      //
      // margin-top: 0;
      //
      padding: 1em;

      dt {
        /*display: inline;*/
        font-weight: bold;

        &::after {
          content: ": ";
        }
      }

      dd {
        margin-left: 0;
        margin-bottom: 0.5em;
      }

      // dd,
      // dt {
      //     margin-bottom: 0.33em;
      // }
    }
  }

  div.item-list {
    padding-left: 1em;
    padding-right: 1em;
  }

  div.item-list.welcome-page {
    padding-left: 0;
    padding-right: 0;
  }

  div.item-list ul.event-list,
  ul.item-list {
    list-style: none;
    padding: 1em;

    &.welcome-page {
      background-color: @color-background-alternate;

      padding-left: 1em;
      padding-right: 1em;
    }

    li {
      .event-date {
        width: 6em;
        margin-right: 1.5em;

        time {
          display: flex;

          .day {
            background-color: @color-background-event-date;

            border-radius: 50%;

            color: @color-event-date;

            display: inline-block;

            flex: 1;

            font-size: 1.3rem;

            line-height: 1.4em;

            margin-right: 0.75em;

            padding: 0.33em;

            text-align: center;

            span:after {
              content: ". ";
            }
          }

          .month-year {
            color: @color-heading2;

            flex: 2;

            .month {
              display: block;

              font-size: 1.3rem;
            }

            .year {
              display: block;
            }
          }
        }
      }

      .event-data {
        margin-top: 1em;

        a {
          text-decoration: none;

          &:link,
          &:visited {
            color: @color-links;
          }

          &.event-title {
            line-height: 1.5em;
          }
        }
      }

      .event-data-row.event-lead-row {
        line-height: 1.5em;
      }

      h3.event {
        margin-top: 0 !important;
      }
    }
  }

  div.contentitem-detail {
    div.main {
      line-height: 1.5em;

      p:first-child {
        margin-top: 0;

        padding-top: 0;
      }

      h2:not(:first-child) {
        margin-top: 0.75em !important;
      }

      ol,
      ul {
        li {
          margin-bottom: 0.33em;
        }
      }
    }
  }

  div.public-personal-profile {
    div.main {
      div.profile-image {
        div.image-attachments {
          float: none;

          padding: 0 1em;

          text-align: center;

          width: 100%;

          figure {
            img {
              max-width: 40vh;

              clip-path: circle(50% at 50% 50%);
            }
          }
        }
      }
    }
  }
}

footer {
  background-color: @color-background-footer;
  color: #fff;
  padding: 3em;

  div {
    // display: flex;
    // .max-width();
    font-size: 0.9rem;

    section {
      // flex: 1;
      // flex-direction: column;
      &.contact {
        p {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      &.legal {
      }

      a {
        &:link,
        &:visited {
          color: #fff;
          text-decoration: none;
        }

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

      p,
      ul {
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          &:not(:first-child) {
            padding-top: 0.5em;
          }

          &:not(:last-child) {
            padding-bottom: 0.5em;
          }
        }
      }
    }
  }
}
/*
 * Special styles for institute sites
 */
body[data-site="www.iaw.uni-bremen.de"] {
}

body[data-site="www.itb.uni-bremen.de"] {
}

body[data-site="www.soziologie.uni-bremen.de"] {
}

body[data-site="www.politik.uni-bremen.de"] {
}
/*
 * Special styles end
 */
@media (max-width: 64.99rem) {
  div.contentitem-detail {
    display: flex;
    flex-direction: column;

    div.side-notes {
      order: 900;
    }
  }
}

@media (min-width: 65rem) {
  
  body {
    margin-top: calc(
      @dim-header-desktop-metamenu-margin-top +
        @dim-header-desktop-metamenu-line-height +
        @dim-header-desktop-metamenu-margin-bottom +
        @dim-header-desktop-logos-img-height +
        @dim-header-desktop-logos-margin-bottom +
        @dim-header-desktop-namebar-line-height +
        @dim-header-desktop-navbar-line-height
    );
  }

  .target-anchor:before,
  :target:before {
    content: " ";

    display: block;

    height: calc(
      @dim-header-desktop-metamenu-margin-top +
        @dim-header-desktop-metamenu-line-height +
        @dim-header-desktop-metamenu-margin-bottom +
        @dim-header-desktop-logos-img-height +
        @dim-header-desktop-logos-margin-bottom +
        @dim-header-desktop-namebar-line-height +
        @dim-header-desktop-navbar-line-height + 1em
    );

    margin: calc(
        -1 * (@dim-header-desktop-metamenu-margin-top +
              @dim-header-desktop-metamenu-line-height +
              @dim-header-desktop-metamenu-margin-bottom +
              @dim-header-desktop-logos-img-height +
              @dim-header-desktop-logos-margin-bottom +
              @dim-header-desktop-namebar-line-height +
              @dim-header-desktop-navbar-line-height + 1em)
      )
      0 0;
  }

  header {
    div#search-mobile,
    div.navbar-mobile,
    nav#nav-mobile {
      display: none;
    }

    .meta-menu {
      padding-right: 0.75em;
    }

    div#logo-bar {
      display: block;
      height: @dim-header-desktop-logos-img-height;
      position: relative;
      margin: @dim-header-desktop-logos-margin-top auto
        @dim-header-desktop-logos-margin-bottom;
      .max-width();

      a.logo-uni {
        position: absolute;
        top: 0;
        left: 0.75em;
      }

      a.logo-institut {
        position: absolute;
        top: 0;
        right: 0.75em;
      }
    }

    div#institute-name-bar {
      padding: 0;

      div.institute-name {
        display: block;

        padding-left: 0.75em;
        padding-right: 0.75em;
      }

      div.institute-name-short {
        display: none;
      }
    }

    nav#nav-desktop {
      background-color: @color-background;

      display: block;

      height: @dim-header-desktop-navbar-line-height;

      & > div {
        .max-width();

        position: relative;

        height: 100%;

        padding-left: 0.75em;
        padding-right: 0.75em;
      }

      & > div > ul {
        display: flex;

        height: 100%;

        list-style: none;

        margin: 0 0 0 -1rem;

        padding-left: 0;

        & > li {
          display: inline-block;

          font-size: 1.375rem;
          // line-height: @dim-header-desktop-navbar-line-height;

          height: 100%;

          &:last-child {
            position: absolute;
            right: 0.75em;
          }

          a {
            display: flex;

            height: 100%;

            // padding-left: 1rem;
            // padding-right: 1rem;
            padding: 0rem 1rem;

            text-decoration: none;

            &:link,
            &:visited {
              color: #fff;
            }

            &.selected,
            &:focus,
            &:hover {
              background-color: @color-button-active;
              color: #fff;
            }

            & > span {
              align-self: center;
              justify-self: center;
            }
          }

          button.selected {
            background-color: @color-button-active;
            color: #fff;
          }

          & > ul {
            display: flex;

            height: 100%;

            list-style: none;

            & > li {
              height: 100%;

              button {
                background-color: transparent;

                border: none;

                color: #fff;

                cursor: pointer;

                display: inline-block;

                // height: 100%;

                // line-height: 3.5rem;

                // overflow: scroll;

                padding: 0.5em 0;

                i {
                  padding-left: 0.35em;
                }

                &:focus,
                &:hover {
                  background-color: @color-button-active;
                }
              }

              button.selected {
                background-color: @color-button-active;
                color: #fff;
              }

              &#nav-desktop-info > button,
              &#nav-desktop-quicklinks > button {
                font-size: 1rem;
                // vertical-align: top;

                height: 100%;

                padding-left: 0.5em;
                padding-right: 0.5em;
              }

              &#nav-desktop-search {
                height: 100%;

                z-index: 1000;

                & > button {
                  font-size: 1.375rem;

                  height: 100%;

                  padding-left: 0.5em;
                  padding-right: 0.5em;

                  i {
                    padding-left: 0;
                  }
                }
              }

              & > div.collapsed {
                display: none;
              }

              & > div.subnav.expanded {
                background-color: @color-background-dialog;
                display: block;
                position: fixed;
                // top: 15rem;
                top: calc(
                  0.8rem + 1rem + 0.8rem + 50px + 20px + 3.5rem + 3.5rem
                );
                right: 0;
                bottom: 0;
                left: 0;
                overflow: scroll;

                & > div {
                  .max-width();
                  position: relative;

                  & > button {
                    background-color: transparent;
                    border: none;
                    color: @color-button-inactive;
                    font-size: 1.5rem;
                    position: absolute;
                    top: 0;
                    right: 0;

                    &:focus,
                    &:hover {
                      color: @color-button-active;
                    }
                  }

                  ul {
                    list-style: none;
                    font-size: 1rem;
                    padding-top: 3rem;
                    width: 16em;

                    li {
                      border-bottom: 1px solid @color-links-mainnav-level2;

                      a {
                        &:link,
                        &:visited {
                          color: @color-links-mainnav-level2;
                        }

                        &:focus,
                        &:hover {
                          background-color: @color-button-active;
                          color: #fff;
                        }
                      }
                    }
                  }
                }

                & > div > div.quicklinks {
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(12.5em, 1fr));
                  grid-gap: 2em;

                  & > div.quicklinks-category {
                    & > h2 {
                      font-size: 1em;
                      line-height: 2em;
                      margin-bottom: 0;

                      & > a {
                        width: 100%;

                        &:link,
                        &:visited {
                          color: @color-text;
                        }

                        &:focus,
                        &:hover,
                        &:active {
                          color: #fff;
                        }
                      }
                    }

                    & > ul {
                      padding-top: 0;
                      padding-left: 0;

                      width: auto;

                      & > li > a {
                        width: 100%;
                      }

                      & li {
                        padding-top: 0.5em;
                        padding-bottom: 0.5em;
                      }

                      & li:last-child {
                        border-bottom: none;
                      }

                      & > li ul {
                        padding-top: 0;
                      }
                    }
                  }
                }
              }

              & > div.expanded.search {
                width: 20rem;
                position: relative;

                & > div > button {
                  background-color: transparent;

                  border: none;

                  color: @color-button-inactive;

                  font-size: 1.5rem;

                  height: auto;

                  position: absolute;
                  top: 0;
                  right: 0;

                  &:focus,
                  &:hover {
                    color: @color-button-active;
                  }
                }

                &.expanded {
                  background-color: @color-background-dialog;
                  display: block;
                  position: fixed;
                  // top: 15rem;
                  top: calc(
                    0.8rem + 1rem + 0.8rem + 50px + 20px + 3.5rem + 3.5rem
                  );
                  right: 0;
                  bottom: 0;

                  h2 {
                    color: @color-button-inactive;
                    font-weight: normal;
                    text-align: center;
                  }

                  input[type="text"] {
                    border: none;
                    display: block;
                    font-size: 1rem;
                    line-height: 3rem;
                    margin: 2rem auto 1.5rem;
                    padding: 0 1rem;
                    width: 80%;
                  }

                  button[type="submit"] {
                    background-color: @color-button-inactive;
                    border: none;
                    color: #fff;
                    display: block;
                    font-size: 1rem;
                    font-weight: bold;
                    line-height: 3rem;
                    margin: 1.5rem auto;
                    width: 80%;

                    &:focus,
                    &:hover {
                      background-color: @color-button-active;
                    }
                  }
                }
              }
            }
          }

          div.nav-desktop-level2 {
            display: none;
          }

          div.nav-desktop-level2.expanded {
            background-color: @color-background-nav-level2;
            display: block;
            padding: 1.5em 2em;
            position: fixed;
            /*top: 15rem;*/
            top: calc(0.8rem + 1rem + 0.8rem + 50px + 20px + 3.5rem + 3.5rem);
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }

      &
        > div
        > ul
        > li
        > div.nav-desktop-level2.expanded
        > button.close-button {
        background-color: transparent;
        border: none;
        color: @color-button-inactive;
        font-size: 1.5rem;
        position: absolute;
        top: 1.5em;
        right: 4rem;

        &:focus,
        &:hover {
          color: @color-button-active;
        }
      }

      &
        > div
        > ul
        > li
        > div.nav-desktop-level2.expanded
        a.category-overview-link {
        color: @color-button-inactive !important;
        font-size: 1.375rem;
        height: auto;
        padding-left: 0.8em;

        & > i {
          margin-right: 0.3em;
        }

        &:focus,
        &:hover {
          background-color: transparent;
          text-decoration: underline;
        }
      }

      & > div > ul > li > div.nav-desktop-level2.expanded ul {
        list-style: none;

        margin-top: 0.5em;

        padding-left: 0;

        width: 14em;
      }

      & > div > ul > li > div.nav-desktop-level2.expanded ul li {
        border-bottom: 1px solid @color-links-mainnav-level2;

        display: block;

        font-size: 1rem;

        list-style: none;

        a {
          padding: 0.8em 0 0.8em 0.8em;

          text-decoration: none;

          width: 100%;

          &:link,
          &:visited {
            color: @color-links-mainnav-level2;
          }

          &.selected,
          &:focus,
          &:hover {
            color: #fff;
          }
        }

        button {
          background-color: transparent;

          border: none;

          color: @color-links-mainnav-level2;

          cursor: pointer;

          font-size: 1rem;

          // line-height: 3.5rem;

          padding: 0.8em 0 0.8em 0.8em;

          position: relative;

          text-align: left;

          width: 100%;

          &.selected {
            background-color: @color-button-active;

            color: #fff;
          }

          i {
            display: inline-block;

            font-size: 1.5rem;

            position: absolute;
            right: 0;

            // line-height: 3.5rem;
            padding-left: 0.5em;
            padding-right: 0.5em;
          }

          span {
            padding-right: 1em;
          }

          &:focus,
          &:hover {
            background-color: @color-button-active;
            color: #fff;
          }

          // &.expanded i {
          //     transform: rotate(180deg);
          // }

          &:after {
            content: "\f105";

            display: inline-block;

            font-family: FontAwesome;
            font-size: 1.5rem;

            position: absolute;
            top: 0.4em;
            right: 0;

            // line-height: 3.5rem;

            padding-left: 0.5em;
            padding-right: 0.5em;
          }

          &.expanded:after {
            transform: rotate(180deg);
          }
        }

        & > div.collapsed {
          display: none;
        }

        & > div.expanded {
          display: block;
          font-size: 1.375rem;
          padding-top: 1em;
          position: absolute;
          top: 0;
          left: 17.5em;
        }
      }
    }
  }

  main {
    .max-width();
    padding-left: 0;
    padding-right: 0;

    div.abstract + div.item-list {
      margin-top: 1em;
    }

    div#contentarea {
      display: flex;
      justify-content: space-between;

      div#contentitem-area {
        flex: 1;
      }

      aside {
        margin: 0 0 0 1em;
        min-width: 15em;
        max-width: 20em;
      }
    }

    div.contentitem-detail.scipublication {
      div.data {
        dl {
        }
      }
    }

    div.ifs-welcome {
      display: grid;
      grid-template-columns: repeat(4, minmax(12.5em, 1fr));
      grid-template-areas:
        "topics1    topics2    topics3    misc1"
        "eventsNews eventsNews eventsNews misc2"
        "info1      info2      info3      misc3";

      position: static;

      #more-info-buttons-mobile {
        display: none;
      }

      #topicBox1 {
        grid-area: topics1;

        position: static;
        top: auto;
        right: auto;

        transform: none;

        z-index: auto;
      }

      #topicBox2 {
        grid-area: topics2;

        position: static;
        top: auto;
        right: auto;

        transform: none;

        z-index: auto;
      }

      #topicBox3 {
        grid-area: topics3;

        position: static;
        top: auto;
        right: auto;

        transform: none;

        z-index: auto;
      }

      #miscBox1 {
        grid-area: misc1;

        position: static;
        top: auto;
        right: auto;

        transform: none;

        z-index: auto;
      }

      #miscBox2 {
        grid-area: misc2;

        position: static;
        top: auto;
        right: auto;

        transform: none;

        z-index: auto;
      }

      #miscBox3 {
        grid-area: misc3;

        position: static;
        top: auto;
        right: auto;

        transform: none;

        z-index: auto;
      }

      #events-and-news {
        grid-area: eventsNews;

        display: flex;

        h2 {
          // span {
          //     background-color: @color-background-alternate;
          //
          //     // display: inline-block;
          //
          //     padding-left: 2em;
          //     padding-right: 2em;
          // }

          background-color: @color-background-alternate;

          padding-left: 1em;
          padding-right: 1em;

          text-align: center;
        }

        div#events,
        div#news {
          border-bottom: 1px solid #ddd;

          margin-right: 0.5em;
        }

        // div:nth-child(even) {
        // h2 {
        //     text-align: right;
        // }
        // }

        ul {
          list-style: none;

          padding-left: 0;

          li {
            border-bottom: 1px solid #ddd;

            margin: 0.5em;
            padding: 0.5em 0;

            &:last-child {
              border-bottom: none;
            }
          }
        }

        #events {
          flex: 4;

          position: static;
          top: auto;
          right: auto;

          transform: none;
        }

        #news {
          flex: 5;

          padding-left: 0;

          ul {
            li {
              div.lead {
                font-weight: normal;

                overflow: auto;

                img {
                  display: block;

                  float: left;

                  width: 20%;
                }
              }
            }
          }
        }
      }

      #infoBox1 {
        grid-area: info1;
      }

      #infoBox2 {
        grid-area: info2;
      }

      #infoBox3 {
        grid-area: info3;
      }

      // .miscBox:nth-child(odd) {
      //     background-color: @color-background-alternate;
      // }
      //
      // .miscBox:nth-child(even) {
      //     background-color: @color-background-nav;
      //     color: #fff;
      //
      //     h2 {
      //         color: #fff;
      //     }
      // }

      .topicBox,
      .infoBox {
        font-size: inherit;

        p {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .topicBox,
      .infoBox,
      .miscBox {
        h2,
        h2.illustrated-header {
          font-size: 1.5em;
        }

        // h2 a {
        //     text-decoration: none;
        // }
        //
        // h2 a:after {
        //
        //     content: " ";
        //
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     bottom: 0;
        //     left: 0;
        // }

        // h2.illustrated-header {
        //
        //     position: relative;
        //
        //     img {
        //         width: 100%;
        //     }
        //
        //     & > span {
        //         // background-color: rgba(255,255,255,0.5);
        //
        //         display: flex;
        //
        //         position: absolute;
        //         top: 0;
        //         right: 0;
        //         bottom: 0;
        //         left: 0;
        //
        //         & > a, & > span {
        //
        //             background-color: rgba(255,255,255,0.5);
        //
        //             flex: 1;
        //             align-self: center;
        //
        //             padding: 0.33em 0.2em;
        //
        //             text-align: center;
        //         }
        //
        //         & > span {
        //             color: @color-links;
        //         }
        //     }
        // }

        img {
          width: 100%;
        }
      }

      dialog.topicBoxDialog {
        position: fixed;
        top: 7.5%;
        right: 15%;
        bottom: 7.5%;
        left: 15%;
        z-index: 999;

        width: calc(100vw - 30%);
        height: calc(100vh - 15%);

        div.dialog-body {
          padding: 0 2em;

          .profiles {
            .profile {
              .profile-body {
                display: flex;
                justify-content: space-between;

                .image {
                  flex-basis: 16%;

                  float: none;

                  margin-top: 0;
                  margin-left: 0;

                  width: 100%;

                  img {
                    display: block;

                    width: 100%;
                    max-width: 500px;
                  }
                }

                .profile-text {
                  flex-basis: 84%;

                  margin-left: 2em;
                }
              }
            }
          }

          .image.left {
            float: left;

            margin-top: 0;

            max-width: 33.3%;
          }
        }
      }

      // .topicBox {
      //     dialog {
      //
      //         background-color: rgba(255,255,255, 0.9);
      //
      //         border: 2px solid @color-background-nav;
      //
      //         display: block;
      //
      //         position: fixed;
      //         top: 1em;
      //         right: 1em;
      //         bottom: 1em;
      //         left: 1em;
      //         z-index: 999;
      //
      //         width: calc(100vw - 2em);
      //
      //         transition: all ease 2s;
      //
      //         button.closeButton {
      //
      //             background-color: #fff;
      //             border: none;
      //
      //             position: absolute;
      //             top: 0;
      //             right: 0;
      //         }
      //
      //         .spinner {
      //             animation: spinner 2.5s infinite ease-in-out;
      //
      //             border: 0.4em solid #ddd;
      //             border-top-color: #999;
      //             border-bottom-color: #999;
      //             border-radius: 50%;
      //
      //             /*display: inline-block;*/
      //             float: left;
      //
      //             margin-right: 0.5em;
      //
      //             width: 2em;
      //             height: 2em;
      //         }
      //
      //         @keyframes spinner {
      //             0% {
      //                 transform: rotate(0deg);
      //             }
      //
      //             100% {
      //                 transform: rotate(540deg);
      //             }
      //         }
      //     }
      // }

      .welcomePageBox {
        margin: 0.5em;

        padding: 0;
      }
    }

    div.infoboxes {
      display: flex;

      div.infobox {
        flex: 1;
      }
    }

    div.item-list.grid {
      ul {
        li {
        }
      }
    }

    div.item-list.sci-departments-list {

      padding-left: 0;
      padding-right: 0;

      ul {
        display: grid;
        grid-gap: 1em 1.5em;
        grid-template-columns: 1fr 1fr;

        li {
          display: block;

          padding: 0.5em 0em;

          div.scidepartment-list {
          }

          figure {
            display: block;

            max-width: 100%;
            width: 100%;

            img {
              width: 100%;
            }
          }
        }
      }
    }

    div.multipart-article {
      div.main {
        display: flex;

        justify-content: space-between;

        div.mpa-toc {
          // float: left;

          width: 17.5em;
        }
      }
    }

    // div.contentitem-detail {
    //
    //     display: flex;
    //     justify-content: space-between;
    //
    //     aside {
    //         margin: 0 0 0 1em;
    //
    //         min-width: 15em;
    //         max-width: 20em;
    //
    //         div.side-note {
    //
    //         }
    //     }
    // }

    div.contentitem-detail.event {
      dl.event-details {
        display: grid;
        grid-template-columns: 9.5em auto;

        margin-top: 0;

        padding: 1em;

        dd,
        dt {
          margin-bottom: 0.33em;
        }
      }
    }

    div.contentitem-detail.scipublication {
      div.data {
        dl {
          display: grid;
          grid-gap: 0.25em 1em;
          grid-template-columns: repeat(2, 1fr);

          div.abstract,
          div.articles,
          div.authors,
          div.collected-volume,
          div.journal,
          div.library-signatures,
          div.proceedings,
          div.proceedings-papers,
          div.year {
            grid-column: span 2;
          }
        }
      }

      aside,
      div.data,
      div.image-attachments {
        flex: auto;
      }
    }

    h2#heading-news {
      div {
        /*.max-width();*/
      }
    }

    div.item-list ul {
      li {
        display: flex;

        figure {
          // max-width: 17.5%;
          // width: 250px;

          img {
            width: 200px;
            // max-width: 25%;
          }
        }
      }
    }

    // div.item-list.event-list ul {
    //
    //     li {
    //         // display: block;
    //
    //         div.event-date {
    //             width: 7em;
    //         }
    //     }
    // }

    div.item-list.welcome-page ul {
      li {
        display: block;

        figure {
          // max-width: 100%;
        }
      }
    }

    div.item-list ul li figure img {
      max-width: none;
    }

    div.image {
      &.left {
        float: left;
      }

      &.Center,
      &.center {
        display: block;

        float: none;
      }

      &.right {
        float: right;
      }

      span.caption {
        display: block;
      }
    }

    div.public-personal-profile {
      div.main {
        display: flex;

        div.profile-image {
          div.image-attachments {
            float: none;

            text-align: inherit;

            width: auto;
          }
        }

        div.profile-data {
          pre.addresstext {
            margin-top: 0;
            padding-top: 0;
          }
        }
      }
    }

    div#contentarea.public-personal-profile-onepage {
      display: flex;

      div.owner-data {
        flex: 1;
        margin-right: 1em;

        div.main {
          display: block;

          .profile-image figure {

            text-align: center;

            img {
              // border-radius: 50%;
              max-width: 12.5em;
              /*max-height: 10em;*/
              clip-path: circle(50% at 50% 50%);
            }
          }
        }

        dl {
          div.profile-data-row {
            display: flex;

            margin-bottom: 1em;

            dt,
            dd {
              display: inline;
            }

            dt {
              font-weight: bold;
              margin-right: 0;
              min-width: 14ch
            }

            dd {
              margin-left: 1ch;

              ul {
                list-style: none;
                
                padding-left: 0;
              }
            }
          }
          div.profile-data-row.address-row {

            display: flex;

            dt {
              margin-right: 0;
            }

            dt,
            dd {
              display: block;

              pre {
                margin-top: 0;
                margin-bottom: 0;
              }
            }
          }
        }
      }

      div#contentitem-area {
        flex: 2;
      }
    }

    div.scidepartment-details.one-page-mode {
      div.info {
        display: flex;

        margin-bottom: 2em;

        div.image {
          flex: 1;
          margin: 0 1em 0 0;

          img {
            max-width: 100%;
          }
        }

        div.description {
          flex: 1;
        }
      }

      div.member-list {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }

      div.members ul {
        display: flex;
        flex-flow: row wrap;

        max-width: 100%;

        list-style: none;

        li {
          display: block;
          width: 20em;

          margin-bottom: 2.5em;

          img {
            //max-width: 100%;
            max-width: 240px;
            max-height: 240px;
            width: auto;
            height: auto;

            clip-path: circle(50% at 50% 50%);
          }

          img.placeholder {
            width: 100%;
          }
        }
      }
    }

    div.contentitem-detail.scidepartment div.members.member-tiles ul {
      display: flex;
      flex-flow: row wrap;

      max-width: 100%;

      list-style: none;

      li {
        display: block;
        width: 20em;

        margin-bottom: 2.5em;

        img {
          max-width: 100%;
        }
      }
    }

    div.scidepartment-details {
      div.tab {
        display: flex;
        justify-content: space-between;

        div.image-attachments {
          flex: 254px;

          width: 254px;

          figure {
            width: 254px;
          }
        }
      }
    }

    div.sciproject-details {
      div.project-summary {
        div.image-attachments {
          width: 40%;
        }
      }
    }

    ul.nav-tabs {
      // background-color: transparent;

      // list-style: none;

      // margin-top: 0;

      // padding-left: 0;

      li {
        // background-color: @color-background-alternate;

        display: inline-block;

        margin-top: 0;
        margin-bottom: 0;

        a {
          display: inline-block;
          // line-height: 1.66em;
          // padding: 0.5em 1em;
          // text-decoration: none;

          &:link,
          &:visited {
            // color: @color-text;
          }

          &:focus,
          &:hover {
            // background-color: @color-links-active;
            // color: #fff;
            // text-decoration: underline;
          }
        }

        &.active {
          a {
            // background-color: @color-background;
            // color: #fff;
          }
        }
      }
    }

    ul.news-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 1em;
      grid-row-gap: 2em;

      li {
      }
    }

    ul.scipublications.export-links {
      li {
        margin-bottom: 0.5em;
      }
    }

    nav.subnav {
      display: block;

      float: left;

      margin: 0 2em 2em 0;

      ul {
        padding-left: 0;

        li {
          line-height: 2.5em;
        }
      }
    }

    ul.event-list.welcome-page {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 1em;
      grid-row-gap: 2em;

      li {
        div.event-date {
        }
      }
    }
  }

  footer {
    div {
      display: flex;
      .max-width();

      section {
        flex: 1;
        flex-direction: column;
      }
    }
  }
}

body.PublicPersonalProfile.pppOnePageMode nav a.selected {
  background-color: transparent !important;

  &:hover,
  &:focus {
    background-color: @color-background-nav-link-active !important;
  }
}

/*
   * Special styles for institute sites
   */
body[data-site="www.iaw.uni-bremen.de"] {
}

body[data-site="www.itb.uni-bremen.de"] {
}

body[data-site="www.soziologie.uni-bremen.de"] {
}

body[data-site="www.politik.uni-bremen.de"] {
}
/*
 * Special styles end
 */
